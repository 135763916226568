import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  IconButton,
  Select,
} from "@mui/material";
import { MenuItem } from "@material-ui/core";
import {
  TrashIcon,
  PlusCircleIcon,
  MinusCircleIcon,
} from "@heroicons/react/24/outline";
import EFFORT_TEAM from "../../constants/effortTeam";
import { deleteBudget } from "../../httpRequests/budget";
import { SET_SNACKBAR } from "../../context/actions/snackbar";
import { useAdminDispatch } from "../../context/AdminContext";

const BudgetInfo = ({
  id,
  budget,
  onDataChange,
  setEffort,
  onClose,
  refetch,
  setRefetch,
}) => {
  const dispatch = useAdminDispatch();
  const [effortData, setEffortData] = useState(budget?.effort || [{}]);
  const [vendor, setVendor] = useState([]);

  useEffect(() => {
    setEffort(effortData);
  }, [effortData]);

  const handleEffortChange = (e, index) => {
    const { name, value } = e.target;
    const updatedEffort = [...effortData];
    updatedEffort[index][name] = name === "type" ? value : parseFloat(value);
    setEffortData(updatedEffort);
  };

  const addNewEffort = () => {
    setEffortData([...effortData, { type: "", amount: "" }]);
  };

  const removeEffort = (index) => {
    setEffortData(effortData.filter((_, i) => i !== index));
    setVendor(vendor.filter((_, i) => i !== index));
  };

  const onClickDeleteBudget = async () => {
    const payload = { isDeleted: true, deletedBy: "Parama" };

    try {
      const response = await deleteBudget(id, payload);
      const success = response.status === 200;
      dispatchSnackbar(
        success,
        success ? "Removed Successfully" : response.error.description
      );
      onClose();
      setRefetch(!refetch);
    } catch (error) {
      console.error(error);
    }
  };

  const dispatchSnackbar = useCallback(
    (success, message) => {
      dispatch({
        type: SET_SNACKBAR,
        payload: {
          open: true,
          message,
          severity: success ? "success" : "error",
        },
      });
    },
    [dispatch]
  );

  return (
    <section className="color">
      <h5>
        Budget Detail{" "}
        {id && (
          <span className="delete">
            <IconButton onClick={onClickDeleteBudget}>
              <TrashIcon />
            </IconButton>
          </span>
        )}
      </h5>
      <Grid container spacing={4}>
        <Grid item md={12}>
          <TextField
            label="Project Name"
            variant="outlined"
            name="projectMainName"
            defaultValue={budget?.projectMainName || ""}
            fullWidth
            size="small"
            onChange={(e) => onDataChange("projectMainName", e.target.value)}
            required
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            label="IC Project Code"
            variant="outlined"
            name="icProjectCode"
            defaultValue={budget?.icProjectCode || ""}
            fullWidth
            size="small"
            onChange={(e) =>
              onDataChange("icProjectCode", parseInt(e.target.value, 10))
            }
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            label="Amount"
            variant="outlined"
            name="amount"
            defaultValue={budget?.amount || ""}
            fullWidth
            size="small"
            onChange={(e) =>
              onDataChange("amount", parseInt(e.target.value, 10))
            }
          />
        </Grid>

        {effortData.map((item, index) => (
          <React.Fragment key={index}>
            <Grid item md={item.type === "Vendor" ? 3 : 6}>
              <FormControl fullWidth required>
                <InputLabel id="effort">Effort</InputLabel>
                <Select
                  name="type"
                  labelId="effort"
                  value={item?.type || ""}
                  label="Effort"
                  size="small"
                  onChange={(e) => handleEffortChange(e, index)}
                >
                  {EFFORT_TEAM.map((team, i) => (
                    <MenuItem key={i} value={team}>
                      {team}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {item.type === "Vendor" && (
              <Grid item md={3}>
                <TextField
                  label="Vendor Name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  onChange={(e) => {
                    const updatedVendors = [...vendor];
                    updatedVendors[index] = e.target.value;
                    setVendor(updatedVendors);
                  }}
                />
              </Grid>
            )}

            <Grid item md={4}>
              <TextField
                label="Effort Amount"
                variant="outlined"
                fullWidth
                type="number"
                name="amount"
                value={item?.amount || ""}
                size="small"
                onChange={(e) => handleEffortChange(e, index)}
              />
            </Grid>

            <Grid item md={2}>
              <div className="add-remove">
                <IconButton
                  onClick={addNewEffort}
                  disabled={!item.type || !item.amount}
                >
                  <PlusCircleIcon />
                </IconButton>

                <IconButton
                  onClick={() => removeEffort(index)}
                  disabled={effortData.length === 1}
                >
                  <MinusCircleIcon />
                </IconButton>
              </div>
            </Grid>
          </React.Fragment>
        ))}

        <Grid item md={12}>
          <TextField
            label="Note"
            variant="outlined"
            name="note"
            fullWidth
            size="small"
            defaultValue={budget?.note || ""}
            multiline
            rows={4}
            onChange={(e) => onDataChange("note", e.target.value)}
          />
        </Grid>
      </Grid>
    </section>
  );
};

export default BudgetInfo;
